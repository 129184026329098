import React, { useEffect } from 'react';
import animateScrollTo from 'animated-scroll-to';
export interface EBannerProps {
  message?: string;
  onClick?: VoidFunction;
  bgColor?: string;
}

const EBanner: React.FC<EBannerProps> = ({ message, onClick, bgColor }) => {
  const bannerBGColor = bgColor ? bgColor : '#9a0b0b';
  const style = {
    backgroundColor: bannerBGColor,
    minHeight: '76px',
    width: '100vw',
    color: 'white',
    padding: '16px var(--site-padding)',

    display: 'flex',
    justifyContent: 'center',
    fontWeight: 700,
    top: 0,
    left: 0,
    zIndex: 100,
    lineHeight: '14px',
    fontSize: '14px',
    flexDirection: 'column'
  } as React.CSSProperties;
  useEffect(() => {
    if (typeof window !== 'undefined' && window.location.hash) {
      if (window.location.hash.slice(1)) {
        return () => { };
      }
    }
    const timeOut = setTimeout(() => {
      animateScrollTo(0);
    }, 200);
    return () => clearTimeout(timeOut);
  }, []);
  return (
    <div style={style} onClick={onClick}>
      {/* <div style={{ fontWeight: 400, paddingBottom: 16 }}>
        Due to adverse weather conditions, many AAA offices, Car Care Centers
        and Fins Car Wash locations have closed early on Friday, Jan. 21, and
        may remain closed over the weekend dependent upon weather. Please call
        before visiting any AAA location, and please avoid driving in adverse
        weather conditions. Thank you.
      </div> */}
      {message ? message : ''}
    </div>
  );
};

export default EBanner;
