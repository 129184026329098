import React from 'react';
import BigText from '../../components/big-text';
import './join.css';
import Button from '../../components/button';

export interface JoinAAAProps {
  number?: string;
  style?: React.CSSProperties;
}

const render2checks = (
  <>
    <div className="col">
      <img src="/icons/blue-check@2x.png" width={20} />
    </div>
    <div className="col">
      <img src="/icons/blue-check@2x.png" width={20} />
    </div>
  </>
);

const render1check = (
  <>
    <div className="col">{}</div>
    <div className="col">
      <img src="/icons/blue-check@2x.png" width={20} />
    </div>
  </>
);

const renderBenefitsHeader = (
  <div className="row">
    <div className="col description"></div>
    <div
      className="col"
      style={{
        padding: '8px 16px 8px 0',
        alignItems: 'center'
      }}
    >
      <p style={{ fontSize: 12, lineHeight: 1.2, marginBottom: 0 }}>
        Fins Wash Club
      </p>
    </div>
    <div
      className="col"
      style={{
        padding: '8px 16px 8px 0',
        alignItems: 'center',
        marginBottom: 0
      }}
    >
      <p style={{ fontSize: 12, lineHeight: 1.2, marginBottom: 0 }}>
        Fins Wash Club + AAA Membership
      </p>
    </div>
  </div>
);

const BenefitItem: React.FC<any> = ({ title, count = 2, gray = false }) => (
  <div
    className="row"
    style={gray ? { backgroundColor: '#F2F2F2' } : undefined}
  >
    <div className="col description">{title}</div>
    {count === 2 ? render2checks : render1check}
  </div>
);

const JoinAAA: React.FC<JoinAAAProps> = ({ number, style }) => {
  return (
    <div id="JoinAAA" style={style}>
      {number && (
        <div className="JoinAAA-background-text">
          <BigText
            value={number}
            style={{ position: 'absolute', right: '-20px', top: '50px' }}
          />
        </div>
      )}
      <div className="JoinAAA-content">
        <div className="JoinAAA-inner-content">
          <div className="JoinAAA-title">
            <div className="JoinAAA-title__content">
              <h3 className="JoinAAA-title__content-top">
                Two Memberships for the Price of One
              </h3>
              <h1 className="JoinAAA-title__content-main">
                Fins Wash Club & AAA
              </h1>
            </div>
          </div>
          <div className="JoinAAA-details">
            <p>
              Did you know AAA Members save $5 per month on{' '}
              <a
                href="http://www.finswash.com/washplans"
                style={{ fontWeight: 'bold', color: '#333' }}
              >
                Fins Wash Club
              </a>
              ? That’s $60/year, $6 MORE than the annual cost of a Basic AAA
              Membership*. Keep your car clean and claim all the benefits of a
              AAA Membership essentially for free.
            </p>
          </div>
        </div>
      </div>

      <div className="cta-btn-group" style={{ justifyContent: 'left' }}>
        <a href="https://join.carolinas.aaa.com/?promo=finslp1&level=basic">
          <Button text="JOIN AAA TODAY" style={{ marginRight: 16 }} />
        </a>
        <a href="http://www.finswash.com/washplans">
          <Button text="FINS WASH PLANS" />
        </a>
      </div>

      <div className="membership-cards">
        <div>
          <img
            src="/img/membership-card-wo-aaa@2x.png"
            className="membership-solo"
          />
          Wash Club Membership Without AAA
        </div>
        <div>
          <img
            src="/img/membership-cards-combined@2x.png"
            className="membership-both"
          />
          Wash Club Membership with AAA
        </div>
      </div>

      <div
        className="horizontal-line"
        style={{
          height: 16,
          backgroundImage: 'linear-gradient(90deg, #cd2127, #063384)'
        }}
      />

      <div className="list-benefits">
        {renderBenefitsHeader}
        <BenefitItem title="Pre-Soak" gray />
        <BenefitItem title="Protective Wash" />
        <BenefitItem title="Spot-Free Rinse" gray />
        <BenefitItem title="Heated Blowers" />
        <BenefitItem title="Wheel Cleaner" gray />
        <BenefitItem title="Vacuums" />
        <BenefitItem title="Mat Cleaners" gray />

        <BenefitItem title="Roadside Assistance" count={1} />
        <BenefitItem title="Vehicle Towing" gray count={1} />
        <BenefitItem title="Fuel Delivery" count={1} />
        <BenefitItem title="Mobile Battery Service" gray count={1} />
        <BenefitItem title="Lockout Service" count={1} />
        <BenefitItem title="Hotel Discounts" gray count={1} />
        <BenefitItem title="Event & Dining Discounts" count={1} />
        <BenefitItem title="Shopping Discounts" gray count={1} />
        <BenefitItem title="Free Travel Planning" count={1} />
        <BenefitItem title="Free Travel Tour Books & Maps" gray count={1} />
        <BenefitItem title="AAA Car Care Discounts" count={1} />
        <BenefitItem title="AAA Insurance Discounts" gray count={1} />
      </div>

      <div style={{ textAlign: 'center' }}>
        <p style={{ fontSize: 12, lineHeight: '18px', fontStyle: 'italic' }}>
          *AAA memberships are paid annually. $17.50 per month is based on the
          cost of a new AAA Basic Membership when enrolled in Auto Renewal
          divided by 12 months, plus the monthly cost of a Basic Fins Wash Club
          membership. Fins Wash Club and AAA Membership are two separate
          transactions.{' '}
        </p>

        <div className="cta-btn-group">
          <a href="https://join.carolinas.aaa.com/?promo=finslp1&level=basic">
            <Button text="JOIN AAA TODAY" style={{ marginRight: 16 }} />
          </a>
          <a href="http://www.finswash.com/washplans">
            <Button text="FINS WASH PLANS" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default JoinAAA;
