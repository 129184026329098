import React from 'react';
import Footer from '../components/footer';
import { Link } from 'gatsby';

import '../styles/promo-page.css';
import { LandingPageHeader } from '../components/header/promo-page';
import JoinAAA from '../sections/joinaaa';

function noop() {
  return;
}

const JoinAAAPage: React.FC<any> = () => {
  return (
    <div id="PromoTemplate" className="Promo-container">
      <LandingPageHeader title="Fins Car Wash" />
      <JoinAAA />

      <Link className="PromoTemplate__back-link" to="/#Promotions">
        &#x3c;&#x3c; Back To Promotions
      </Link>
      <Footer items={[]} onNavItemSelected={noop} />
    </div>
  );
};

export default JoinAAAPage;
