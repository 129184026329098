import React from 'react';
import { useMedia } from '../../utils/hooks';
import SEO from '../seo';
import FinsWashIcon from '../icons/finswash';
import EmergencyBanner from '../emergency-banner';
import { Link } from 'gatsby';

interface LandingPageProps {
  title: string;
  emergencyMessage?: string;
}

export const LandingPageHeader: React.FC<LandingPageProps> = ({
  title = 'Fins Car Wash',
  emergencyMessage // = 'FINS is taking preventative measures to limit the spread of COVID-19 and our operating locations are currently open.'
}) => {
  const iconSize = useMedia(
    ['(min-width: 376px)', '(min-width: 275px)'],
    [
      { width: '206', height: '40' },
      { width: '190', height: '37.54' }
    ],
    { width: '206', height: '40' }
  );

  return (
    <>
      <SEO
        title={title}
        description="Fins Car Wash is for car lovers--no matter the make, model or age of your car. Our motto is FAST, FUN and FINTASTIC."
      />
      {emergencyMessage && <EmergencyBanner message={emergencyMessage} />}
      <svg
        className="PromoTemplate__background-img"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="1%" stopColor="#649A30" />
            <stop offset="80%" stopColor="#154288" />
          </linearGradient>
        </defs>
        <polygon fill="url(#gradient)" points="0 0 10 0 0 10" />
      </svg>
      <div className="Promo-container__header">
        <Link to="/">
          <FinsWashIcon color="#16478E" {...iconSize} />
        </Link>
      </div>
    </>
  );
};
